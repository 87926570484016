.tech-container {
  margin-top: -60px;
  background-color: #08aeea;
  background-image: linear-gradient(
    to bottom,
    hsl(324.94, 63.52%, 50.4%) 0%,
    hsl(310.39, 46.14%, 49.68%) 31.5%,
    hsl(296.53, 39.12%, 49.7%) 41%,
    hsl(280.63, 42.91%, 53.43%) 50.6%,
    hsl(265.14, 47.59%, 56.84%) 61.5%,
    hsl(250.13, 52.52%, 59.88%) 76.3%,
    hsl(235.88, 59.2%, 60.91%) 91.3%,
    hsl(225.81, 68.23%, 57.85%) 100%
  );
  color: #fff;
  overflow: hidden;
}

.history-wrapper a img {
  width: 150px;
  margin: 0 auto;
}
