.hero-container {
  height: 100vh;
  background-color: #08aeea;
  background-image: linear-gradient(
    to bottom,
    hsl(331, 89.25%, 47.36%) 0%,
    hsl(330.53, 79.69%, 48.96%) 8.5%,
    hsl(328.56, 70.89%, 49.96%) 16.6%,
    hsl(324.94, 63.52%, 50.4%) 21%,
    hsl(310.39, 46.14%, 49.68%) 31.5%,
    hsl(296.53, 39.12%, 49.7%) 41%,
    hsl(280.63, 42.91%, 53.43%) 50.6%,
    hsl(265.14, 47.59%, 56.84%) 61.5%,
    hsl(250.13, 52.52%, 59.88%) 76.3%,
    hsl(235.88, 59.2%, 60.91%) 91.3%,
    hsl(225.81, 68.23%, 57.85%) 100%
  );
  position: relative;
  overflow: hidden;
}

#hero-arc {
  width: 100%;
}

.hero-btn-container {
  bottom: 10%;
  -webkit-animation: 5s ease 0s normal forwards 1 fadein;
  animation: 5s ease 0s normal forwards 1 fadein;
}

.chevron {
  margin: auto;
  left: -1px;
  top: 6px;
  bottom: 0;
  right: 0;
}

.pulse {
  height: 50px;
  width: 50px;
  opacity: 0;
  border: 1px solid white;
  border-radius: 40px;
  animation: pulsate 1.7s ease-out;
  animation-iteration-count: infinite;
  -webkit-animation: pulsate 1.7s ease-out;
  -webkit-animation-iteration-count: infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.3, 1.3);
    opacity: 0;
  }
}

@-webkit-keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.3, 1.3);
    opacity: 0;
  }
}
