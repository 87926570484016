.text-top {
  font-size: 90px;
  letter-spacing: 37px;
  font-weight: bold;
  margin: 0 -35px 0 0;
}

.text-bottom {
  font-size: 45px;
  margin: -5px -4px 0 0;
  letter-spacing: 10px;
  font-weight: 300;
  font-style: italic;
  margin-bottom: 50px;
}

#logo {
  width: 400px;
}

.text-top,
.text-bottom {
  text-transform: uppercase;
}

#planet,
.text-top,
.text-bottom {
  -webkit-animation: 4s ease-in 0s normal forwards 1 fadein;
  animation: 4s ease-in 0s normal forwards 1 fadein;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  #logo {
    width: 220px;
  }

  .text-top {
    font-size: 40px;
  }

  .text-bottom {
    font-size: 22px;
    margin-top: 0;
  }
}
