.service-icons {
  width: 70px;
}

.animate-box {
  -webkit-animation: AnimateBackground 2s ease infinite;
  -moz-animation: AnimateBackground 2s ease infinite;
  animation: AnimateBackground 2s ease infinite;
}

@-webkit-keyframes AnimateBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimateBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimateBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
